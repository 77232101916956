@import 'assets/scss/base';

.Page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 1%;
  min-height: 0;
  flex: 1 1 auto;
  overflow: auto;
}

.PageContent {
  display: flex;
  flex-direction: column;
}

.PageContent {
  padding: $pageContentPadding;
}

.PageContentNoPadding {
  .PageContent {
    padding: 0;
  }
}

@include media-mediumscreen-down {
  .PageContent {
    padding: $pageContentPaddingMediumDown;
  }

  .PageContentNoPadding {
    .PageContent {
      padding: 0;
    }
  }
}

@include media-xlargescreen {
  .Page {
    &:not(.PageFullWidth) {
      .PageContent {
        max-width: 1920px;
      }
    }
  }

  .PageContent {
    width: 100%;
    margin: 0 auto;
  }
}
