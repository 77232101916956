.BtnBack {
  position: absolute;
  left: 0px;
  top: 18px;
}
.TitleCenter {
  width: 100%;
  text-align: center;
}
.CardElementBaseStyle {
  font-family: Space Grotesk;
  font-size: 16px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.07);
  padding: 16px 12px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
  &.Hidden {
    display: none;
  }
}
.CardElementFocusStyle {
  border-bottom: 1px solid #f9bd19;
}

.CreditCardInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 12px;
}

.CreditCardInfo {
  display: flex;
  align-items: center;
}

.CreditCardInfoIcon {
  margin-right: 10px;
  width: 23.5px;
  height: 15.5px;
}

.CardPanelLoadingWrapper {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 34px;
}

.CardPanelLoading {
  margin-bottom: 24px;
}
