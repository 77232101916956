/*  */
$value2: convertPixelToRem(2);
$value3: convertPixelToRem(3);
$value4: convertPixelToRem(4);
$value5: convertPixelToRem(5);
$value6: convertPixelToRem(6);
$value7: convertPixelToRem(7);
$value8: convertPixelToRem(8);
$value9: convertPixelToRem(9);
$value10: convertPixelToRem(10);
$value12: convertPixelToRem(12);
$value14: convertPixelToRem(14);
$value16: convertPixelToRem(16);
$value18: convertPixelToRem(18);
$value20: convertPixelToRem(20);
$value24: convertPixelToRem(24);
$value28: convertPixelToRem(28);
$value32: convertPixelToRem(32);
$value36: convertPixelToRem(36);
$value40: convertPixelToRem(40);
$value44: convertPixelToRem(44);
$value48: convertPixelToRem(48);
$value56: convertPixelToRem(56);
$value60: convertPixelToRem(60);
$value64: convertPixelToRem(64);
$value72: convertPixelToRem(72);
$value100: convertPixelToRem(100);
