/* Mixins */
@import 'assets/scss/base';

/* Styles */
.Checkbox {
  user-select: none;
}

.ColorSecondaryAlt {
  color: $colorSecondary;
}

.FormControlCheckbox {
  align-items: flex-start;
  margin: 0;
  padding: 9px;
  user-select: none;

  .Checkbox {
    margin-top: -9px;
    margin-left: -9px;
    margin-bottom: -9px;
  }

  &:global(.MuiFormControlLabel-labelPlacementStart) {
    .Checkbox {
      margin: -9px -9px -9px 0;
    }
  }

  &:global(.MuiFormControlLabel-labelPlacementStart) .FormControlCheckboxLabel {
    padding-left: 0;
    padding-right: 15px;
  }

  &:global(.MuiFormControlLabel-labelPlacementTop) {
    .FormControlCheckbox {
      align-items: center;
    }

    .FormControlCheckboxLabel {
      padding-left: 0;
      padding-bottom: 15px;
    }

    .Checkbox {
      margin: 0 -9px -9px -9px;
    }
  }

  &:global(.MuiFormControlLabel-labelPlacementBottom) {
    .FormControlCheckbox {
      align-items: center;
    }

    .FormControlCheckboxLabel {
      padding-left: 0;
      padding-top: 15px;
    }

    .Checkbox {
      margin: -9px -9px 0 -9px;
    }
  }
}

.FormControlCheckboxLabel {
  padding-left: 15px;
}

.FormControlCheckboxLabelCustom {
  line-height: 1;
  display: flex;
  margin: auto;
}
