@import 'assets/scss/base';

.LargeImagePreviewContainer {
  height: 100%;
}

.Border {
  border: 1px solid $colorWhiteOpacity38;
}

.Canvas {
  image-rendering: crisp-edges;
}

.ImageLoadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(39, 39, 39, 0.6);
  z-index: 1;
}

.DropdownContainer {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  z-index: 1200;

  button {
    padding: 10px;
    color: rgba(255, 255, 255, 1);
    background-color: rgb(0 0 0 / 40%);
  }
  button:hover {
    background-color: rgb(0 0 0 / 60%);
  }
}
@include media-smallscreen-down {
  .DropdownContainer {
    bottom: 10px;
    right: 2px;
  }
}
