/* Imports */
@import 'assets/scss/base';

/* Styles */

.UpscaleListContent {
  flex: 1 1 auto;
  height: 1%;
  min-height: 0;
}

/* Responsive */
@include media-smallscreen {
  .UpscaleListHead {
    height: convertPixelToRem(88);
    padding-left: convertPixelToRem(72);
    padding-right: convertPixelToRem(72);
  }
}
