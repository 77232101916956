@import 'assets/scss/base';

/* Global Styles */
/* UTILS */
/* Utils - Background */
.BgNone {
  background: none !important;
}

//
.BgColorInherit {
  background-color: inherit !important;
}

//
.BgColorSecondaryFocus {
  background-color: $colorSecondaryFocus !important;
}

/* Utils - Border */
.BorderColorOnSurface {
  border-color: $colorWhiteFocus !important;
}

.Border0 {
  border: 0 !important;
}

.BorderRoundedFull {
  border-radius: 9999px !important;
}

.BorderSecondary {
  border: $borderSecondary !important;
}

.BorderOnSurface {
  border: $borderOnSurface !important;
}

.BorderWhiteMed {
  border: $borderWhiteMedTextOnDark !important;
}

.BorderWhiteHi {
  border: $borderWhiteHiTextOnDark !important;
}

.BorderTransparent {
  border: 1px solid transparent !important;
}

.BorderTopOnSurface {
  border-top: $borderOnSurface !important;
}

.BorderBottomOnSurface {
  border-bottom: $borderOnSurface !important;
}

.BorderLeftOnSurface {
  border-left: $borderOnSurface !important;
}

.BorderRightOnSurface {
  border-right: $borderOnSurface !important;
}

.BorderTopWhiteHi {
  border-top: $borderWhiteHiTextOnDark !important;
}

.BorderBottomWhiteHi {
  border-bottom: $borderWhiteHiTextOnDark !important;
}

.BorderTopWhiteMed {
  border-top: $borderWhiteMedTextOnDark !important;
}

.BorderRoundedNone {
  border-radius: 0 !important;
}

//
.TextWhite38 {
  color: $buttonDisabledColor !important;
}

.TextColorWhiteOpacity38 {
  color: $colorWhiteOpacity38 !important;
}
