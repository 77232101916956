@import 'assets/scss/base';

.Dialogs {
  width: 100%;
  height: 100%;
}
.LoadingStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.ListStyle {
  li {
    margin-bottom: 8px;
  }
}

.Checkbox {
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
}

.CropperToolBoxSelect {
  min-width: 130px;
}

.ImageGuideContainer {
  display: flex;
  height: 500px;
  overflow-x: scroll;
  overflow-y: hidden;
  div {
    margin-right: 12px;
    margin-bottom: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      height: 85%;
    }
  }
}

@include media-smallscreen-down {
  .CroppperImage {
    margin-left: 0;
  }
}

.LoadingContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.DialogContent:first-child {
  padding-top: 0;
}
.EmailDialog {
  :global(.MuiDialogActions-root) {
    justify-content: space-between;
  }
}

.ConfirmationDialog {
  :global(.MuiPaper-root) {
    min-width: 300px;
    max-width: 550px;
  }
}

.InformationDialog {
  :global(.MuiPaper-root) {
    min-width: 300px;
    max-width: 450px;
  }
}

/* Login Required Dialog */
.LoginRequiredDialog {
  :global(.MuiDialog-paper) {
    max-width: 768px;
  }

  .ButtonClose {
    position: fixed;
    top: 0;
    right: 0;
    margin: 12px;
    font-size: 48px;

    &:global(.MuiButton-text) {
      padding: 0;
    }
  }

  .SignInContainer {
    max-width: 536px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*  */
.TutorialDialog {
  :global(.MuiPaper-root) {
    width: 560px;
    max-width: 100vw;
  }
  :global(.MuiDialogActions-root) {
    justify-content: flex-end;
  }
}
.ActionRight {
  :global(.MuiDialogActions-root) {
    justify-content: flex-end;
  }
}
.MetamaskWalletDialogContent {
  min-width: 400px;
}
.DialogContents {
  padding: 0 24px 24px 24px;
  min-width: 300px;
  max-height: 600px;

  &.WithTopPadding {
    padding-top: 24px;
  }
}

.TopDivider {
  margin-bottom: 24px;
}

.ContentGroup {
  margin-top: 24px;
  max-width: 600px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
    }
  }
  &.DottedList {
    ul {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      list-style: disc;
      margin-left: 17px;
      li {
        margin-bottom: 24px;
      }
    }
  }
  &.CompactList {
    ul {
      li {
        margin-bottom: 5px;
      }
    }
  }
  &.GreyText {
    color: $textGray;
    p {
      color: $textGray;
    }
  }
  &:first-child {
    margin-top: 0;
  }

  .Video {
    max-width: 100%;
  }
  &.FormContainer {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin-bottom: 8px;
  }
  a {
    color: $textGray;
    text-decoration-color: currentColor;
  }
  p {
    margin-bottom: 8px;
  }
}

.ContentGroup.Image {
  display: flex;
  img {
    width: 80%;
    margin: 0 auto;
  }
}

/* Dialog Phone Verification */
.DialogPhoneVerifyPaper {
  max-width: 560px;
  overflow: initial;
}

.DialogPhoneVerifyContent {
  overflow: initial;
}

.DialogPhoneVerifyInputWrapper {
  &:last-child {
    margin-bottom: 24px;
  }
}

.DialogPhoneVerifyInput {
  max-width: 328px;

  :global(.MuiInputBase-root.Mui-disabled) {
    background-color: $colorBackgroundInput;
    border-bottom: 1px solid $colorWhiteMedTextOnDark;
    color: $colorWhite;
  }
  :global(.MuiFormLabel-root.Mui-disabled) {
    color: $buttonDisabledColor;
  }
}

/* Email Preferences dialog */
.DialogEmailPrefOptionsContainer {
  margin-right: -9px;
}

.DialogEmailPrefActions {
  margin-top: 12px;
  text-align: right;
}

/* Post Delete dialog */
.DeletePostDialogSmallPaper {
  max-width: 416px;
}

.DeletePostDialogPaper {
  max-width: 748px;
}

/* Utils */
.TextLight {
  color: $colorWhiteMedTextOnDark;
}
