@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/scss/base';

:root {
  /*  */
  --color-secondary: #{$colorSecondary};
  --color-success: #{$colorSuccess};
  --color-danger: #{$colorDanger};

  /* Background */
  --bg-dialog: #{$dialogSurfaces};
  --bg-light: #{$backgroundLight};
  --bg-lighter: #{$backgroundLighter};
  --bg-primary: #{$background};
  --bg-secondary: #{$colorSecondary};

  --text-white-med-on-dark: #{$colorWhiteMedTextOnDark};
  --text-white-hi-on-dark: #{$colorWhiteHiTextOnDark};
  // --color-white-focus: #{$colorWhiteFocus};
  // --text-color-body: #{$textBody};

  // Link
  // --link-color-primary: #{$textBody};
  // --link-color-secondary: #{$secondaryColor};
}

/* Global Styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
input:-internal-autofill-selected,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: none;
  transition: background-color 5000s ease-in-out 0s;
  background-color: currentColor !important;
  background-image: none !important;
  color: rgba(255, 255, 255, 0.87);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}

html,
body,
#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $background;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  touch-action: manipulation;

  &.OverflowHidden {
    overflow: hidden;
  }

  &.useCustomWebkitScrollbar {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar:hover,
    ::-webkit-scrollbar:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }

    &::-webkit-scrollbar-thumb:vertical,
    ::-webkit-scrollbar-thumb:vertical {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 100px;
      background-clip: padding-box;
      border: 1px solid transparent;
      min-height: 10px;
    }

    &::-webkit-scrollbar-thumb:horizontal,
    ::-webkit-scrollbar-thumb:horizontal {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 100px;
      background-clip: padding-box;
      border: 1px solid transparent;
      min-width: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical:active,
    &::-webkit-scrollbar-thumb:horizontal:active,
    ::-webkit-scrollbar-thumb:vertical:active,
    ::-webkit-scrollbar-thumb:horizontal:active {
      background: rgba(255, 255, 255, 0.37);
      border-radius: 100px;
      background-clip: padding-box;
    }
  }
}

/* Scroll styles for Firefox browser */
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-color: rgba(255, 255, 255, 0.12) rgba(255, 255, 255, 0);
    scrollbar-width: thin;
  }
}
/* Scroll styles for Firefox browser END */

@font-face {
  font-family: 'Space Grotesk';
  src:
    url('./assets/fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
    url('./assets/fonts/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src:
    url('./assets/fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
    url('./assets/fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src:
    url('./assets/fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
    url('./assets/fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src:
    url('./assets/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
    url('./assets/fonts/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src:
    url('./assets/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Friend Buy */
[id$='frndby_container'],
[id$='frndby_bg'] {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

[id$='frndby_container'] {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100002;
    display: block;
    width: 44px;
    height: 44px;
    margin-left: -22px;
    margin-top: -154px;
    border-top: 4px solid $colorSecondary;
    border-right: 4px solid $colorSecondary;
    border-bottom: 4px solid transparent;
    border-left: 4px solid $colorSecondary;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 100%;
    -webkit-animation: spinning 1.1s infinite linear;
    animation: spinning 1.1s infinite linear;
    text-indent: -9999em;
  }

  [id$='frndby_unit'] {
    top: 50% !important;
    font-size: 0;
    transform: translate(0, -50%);
  }

  [id$='frndby_unit_loading'] {
    display: none;
  }

  [id$='frndby_close_btn'] {
    left: auto !important;
    top: 4px !important;
    right: 4px;
    max-width: unset !important;
    min-width: unset !important;

    img {
      max-width: 32px !important;
      padding: 4px;
      opacity: 0.87;
      border-radius: 100%;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        background-color: #eee;
      }
    }
  }
}

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Responsive */
@media only screen and (min-width: 600px) and (max-width: 1023px) {
  [id$='frndby_unit'] {
    max-width: 480px !important;
    margin-left: -240px !important;
  }
}

/* Tailwind Directives */
// .border-onSurface {
//   @apply border-solid;
// }
