//
//  VARIABLES
// --------------------------------------------------

$fontSizeBase: 16; // font size base in px
$fontFamily: 'Space Grotesk';

// Screen Resolution Variables
$smallscreen: 600px;
$mediumscreen: 1024px;
$largescreen: 1440px;
$xlargescreen: 1920px;

//Color
// $colorPrimary: #f9bd19;
// $colorPrimaryHover: rgba(249, 189, 25, 0.04);
// $colorPrimaryFocus: rgba(249, 189, 25, 0.12);
// $colorPrimaryOverlay: rgba(249, 189, 25, 0.24);

$colorSecondary: #f9bd19;
$colorSecondaryHover: rgba(249, 189, 25, 0.04);
$colorSecondarySelectedOverlay: rgba(249, 189, 25, 0.08);
$colorSecondaryFocus: rgba(249, 189, 25, 0.12);
$colorSecondaryOverlay: rgba(249, 189, 25, 0.24);

$colorSuccess: #c5dd2e;
$colorDanger: #f3798f;

$colorHiTextOnPrimary: #000;
$colorMedTextOnPrimary: rgba(0, 0, 0, 0.87);
$colorBlack87: rgba(0, 0, 0, 0.87);
$colorBlack50: rgba(0, 0, 0, 0.5);
$colorWhiteMedTextOnDark: rgba(255, 255, 255, 0.6);
$colorWhiteHiTextOnDark: rgba(255, 255, 255, 0.87);
$colorWhiteFocus: rgba(255, 255, 255, 0.12);
$colorWhiteHover: rgba(255, 255, 255, 0.08);
$colorWhiteOverlay: rgba(255, 255, 255, 0.04);
$colorWhiteOpacity38: rgba(255, 255, 255, 0.38);
$colorWhite: #fff;

$buttonSecondary: $colorSecondary;
$buttonSecondaryHover: $colorSecondaryHover;
$buttonSecondaryFocus: $colorSecondaryFocus;
$buttonSecondaryOverlay: rgba(249, 189, 25, 0.24);
$buttonSecondaryRipple: rgba(249, 189, 25, 0.1);
$buttonSecondarySelectedOverlay: rgba(249, 189, 25, 0.08);

$buttonDisabledColor: $colorWhiteOpacity38;
$buttonDisabledBackground: $colorWhiteFocus;
$buttonErrorColor: $colorDanger;
$textGray: rgba(255, 255, 255, 0.6);

$background: #121212;
$backgroundTransparent: rgba(18, 18, 18, 0.8);
$backgroundLight: #272727;
$backgroundLighter: #2e2e2e;
$dialogSurfaces: #383838;
$colorBackgroundInput: rgba(255, 255, 255, 0.07);
$colorSurfaceSheetBar: $backgroundLighter;
$backdropColor: rgba(0, 0, 0, 0.5);

// Border
$borderSecondary: 1px solid $colorSecondary;
$borderOnSurface: 1px solid $colorWhiteFocus;
// $borderSecondary: 1px solid ${secondary.main};
$borderWhiteMedTextOnDark: 1px solid $colorWhiteMedTextOnDark;
$borderWhiteHiTextOnDark: 1px solid $colorWhiteHiTextOnDark;
$borderHairlineBright: $borderWhiteMedTextOnDark;

//Page Padding
$pageContentPadding: 24px 72px;
$pageContentPaddingMediumDown: 24px;

//Navbar height
$navbarHeight: 72px;
$statusBarHeight: 53px;

// Duration
$transitionDuration: 0.3s;
