@import 'assets/scss/base';

/* Style */
.ImageLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EmptyImage {
  background: #4f4f4f;
  width: 100%;
}

/*  */
.DivImage {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.DivImageInner {
  position: absolute;
  top: 0;
  left: 0;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  width: 100%;
  height: 100%;
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}

/* Image Thumbnail Empty */
.ImgEmptyThumbnail {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}

/* Image Scrambled */
.ImageScrambledContainer {
  position: relative;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.ImageScrambledItem {
  position: absolute;
  z-index: 1;
}

.ImageScrambledImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Utils */
.FullWidth {
  width: 100%;
}

.FullHeight {
  height: 100%;
}

.BgContain {
  background-size: contain;
}

.BgCover {
  background-size: cover;
}


.ObjectScaleDown {
  object-fit: scale-down;
}

.ObjectContain {
  object-fit: contain;
}

.ObjectCover {
  object-fit: cover;
}

.PointerEventsNone {
  pointer-events: none;
}

.UserSelectNone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
