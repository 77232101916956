/* Mixins */
@import 'assets/scss/base';

/* Styles */
.HostedField {
  height: 48px;

  :global(.number) {
    background: #f00;
  }
}

/* Text Field */
.TextField {
  position: relative;
}

.TextFieldUnmounted {
  height: 0;
  content-visibility: hidden;
  visibility: hidden;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0px, 0px, 0px, 0px);
  pointer-events: none;
  user-select: none;
}

.TextFieldBordered {
  &:before {
    border-bottom: $borderWhiteMedTextOnDark;
    left: 0px;
    bottom: 0px;
    content: '';
    position: absolute;
    right: 0px;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  &:after {
    border-bottom: 2px solid $colorSecondary;
    left: 0px;
    bottom: 0px;
    content: '';
    position: absolute;
    right: 0px;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
}

.TextFieldFocused {
  &:after {
    transform: scaleX(1);
  }
}

.TextFieldBrandIcon {
  width: convertPixelToRem(20);
  height: auto;
}

.TextFieldExpiredDate {
  width: convertPixelToRem(80);
}

.TextFieldCVV {
  width: convertPixelToRem(50);
}

.TextFieldPostalCode {
  width: convertPixelToRem(100);
}
