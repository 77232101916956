@import 'assets/scss/base';

/*  */
.Link {
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.LinkWithAddOn {
  display: inline-flex;
  align-items: center;
}

.LinkWithAddOnContent {
  margin-right: $value8;
}

/* Color */
.ColorPrimary {
  color: $colorWhiteHiTextOnDark;

  &:hover {
    color: $colorWhiteMedTextOnDark;
  }
}

.ColorSecondary {
  color: $colorSecondary;

  &:hover {
    color: darken($colorSecondary, 15%);
  }
}

.ColorLight {
  color: $colorWhiteMedTextOnDark;

  &:hover {
    color: $colorWhiteOpacity38;
  }
}
