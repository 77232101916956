.Snackbar {
  background: rgba(255, 255, 255, 0.87);
  color: rgba(0, 0, 0, 0.87);
  bottom: 102px;
  min-width: 344px;
  .SnackbarContent {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    &.WithoutAction {
      justify-content: center;
    }
    .SnackbarMessage {
      span {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        background: rgba(0, 0, 0, 0.87);
      }
    }
    .SnackbarAction {
      padding-left: 8px;

      span {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        color: #f57a00;
        text-transform: uppercase;
      }
    }
  }
}
