/* Mixins */
@import 'assets/scss/base';

/* Styles */
.SigninContainer,
.SigninContainerDialog {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.SigninContainer {
  padding-left: 24px;
  padding-right: 24px;
}

.SigninBox {
  min-height: 416px;
  background: none;
}

.LoadingContainer {
  width: 100%;
  height: 416px;
}

/* Signin Section */
.SigninFormSection {
  padding: 72px 24px;
}

.SigninSectionFeatured {
  padding-top: 50px;
  padding-bottom: 24px;
}

.SigninSectionTestimonial {
  padding-top: 50px;
  padding-bottom: 50px;
}

.FeaturedImage {
  min-height: 10px;
  padding-left: 26px;
  padding-right: 26px;
}

.FeatureGridImgWrapper {
  padding-bottom: 56.135%;
}

.CarouselTestimonialWrapper {
  max-width: convertPixelToRem(1200);
}

/* Responsive */
@include media-smallscreen {
  .SigninBox {
    min-width: 416px;
    max-width: 536px;
  }

  .SigninFormSection {
    padding-top: 108px;
    padding-bottom: 108px;
  }

  .FeaturedImageWrapper {
    max-width: 12.5%;
  }

  .SigninSectionFeatured {
    padding-bottom: 60px;
  }

  .SigninSectionTestimonial {
    padding: 116px 24px 80px;
  }
}

@include media-mediumscreen {
  .SigninContainer {
    padding-left: 182px;
    padding-right: 182px;
  }
}
