/* Imports */
@import 'assets/scss/base';

/* Styles */
.UpscaleListHead {
  height: convertPixelToRem(57);
}

/* Upscale Card */
.UpscaleCardLoading {
  background-color: rgba(0, 0, 0, 0.74);
}

@include media-mediumscreen {
  .UpscaleListHead {
    height: convertPixelToRem(88);
  }

  .UpscaleCardMenuMore {
    opacity: 0;
  }

  .UpscaleCard:hover .UpscaleCardMenuMore {
    opacity: 1;
  }
}
