/* Import */
@import 'assets/scss/base';

/* Styles */
.DialogUpscaleDetail {
  max-width: 990px;
}

.UpscaleDetailDesktopContainer {
  height: convertPixelToRem(526);
}

/* Upscale Detail Mobile */
.UpscaleDetailMobile {
  z-index: 1251;
}

.UpscaleDetailPreviewContainerMobile {
  min-height: convertPixelToRem(150);

  @media only screen and (min-height: 699px) {
    min-height: convertPixelToRem(250);
  }
}

/* Responsive */
@include media-smallscreen {
  .UpscaleDetailContent {
    flex: 1 1;
  }
}

@include media-mediumscreen {
  .UpscaleDetailContent {
    width: convertPixelToRem(464);
  }
}

@include media-smallscreen-down {
  .UpscaleDetailContent {
    height: 1%;
    min-height: 0;
    flex: 1 1 auto;
  }

  .UpscaleDetailActionHelper {
    border-top: $borderOnSurface;
  }
}
