/* Mixins */
@import 'assets/scss/base';

/* Styles */
.SelectBase {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-bottom: $borderOnSurface;
  }
}

.SelectBaseOpen {
  &:after {
    border: $borderOnSurface;
  }
}

.SelectBaseBorderless {
  &:after {
    border-bottom-color: transparent;
  }
}

.SelectBasePaper {
  margin-top: -1px;
  background: #2e2e2e;
  box-shadow: none;
  border: $borderOnSurface;
}

.SelectBaseInput {
  position: relative;
  z-index: 1;
  min-width: 50px;
  min-height: 20px;
  padding: convertPixelToRem(14) convertPixelToRem(16);
  font-size: convertPixelToRem(14);
}

.SelectBaseIcon {
  top: calc(50% - 0.5em);
  margin-right: 5px;
}

.SelectBaseInput {
  .MenuItemLabel {
    overflow: hidden;
  }
}

/* Size */
.SelectBaseSmall {
  .SelectBaseInput {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
