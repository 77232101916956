@import 'assets/scss/base';

.DropdownContainer {
  text-align: left;
}

.DropdownPortalLess {
  position: relative;
}

.DropdownButton {
  display: flex;
}

.DropdownMenu {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212;
  padding: $value8 0;
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: convertPixelToRem(120);
  outline: 0;
}

.MenuItem {
  padding: $value8 $value16;
  font-size: $value14;
  line-height: $value20;
  letter-spacing: 0.25px;
  color: $colorWhiteHiTextOnDark;

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.04);
    // color: #e1b897;
  }

  &.Disabled {
    color: $buttonDisabledColor;
    pointer-events: none;

    &:hover,
    &:active {
      background: none;
      color: $buttonDisabledColor;
    }
  }
}

.MenuItemError {
  color: $colorDanger;
}

.ButtonIconContainer {
  position: relative;
}

.ButtonLabelArrow {
  margin-left: $value4;
  margin-right: convertPixelToRem(-4);
}

// HACK
div[role='tooltip'] {
  z-index: 1550;
}
