/* Import */
@import 'assets/scss/base';

/*  */
.DialogTitle {
  display: flex;
  padding: 0;
}

.DialogTitleContent {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.DialogTitleCloseWrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.DialogTitleClose {
  margin-top: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
}

.DialogContent {
  height: 100%;
  overflow-x: hidden;
}

.DialogContentWithPadding {
  padding-top: 24px;
  padding-bottom: 24px;
}

.DialogContentWithoutPadding {
  padding: 0;

  &:first-child {
    padding-top: 0;
  }
}

.DialogActions {
  padding: 0;
}

.DialogButtonCloseFloating {
  position: fixed;
  top: 12px;
  right: 12px;
  padding: 0 !important;
  font-size: 48px;
  z-index: 1;
}

/* Utils */
.Padding0 {
  padding: 0;
}

.Padding24 {
  padding: 24px;
}

.PaddingX24 {
  padding-left: 24px;
  padding-right: 24px;
}

.PaddingY24 {
  padding-bottom: 24px;
  padding-top: 24px;
}

.PaddingTop24 {
  padding-top: 24px;
}

.PaddingBottom24 {
  padding-bottom: 24px;
}

.BorderTopOnSurface {
  border-top: $borderOnSurface;
}

.BorderBottomOnSurface {
  border-bottom: $borderOnSurface;
}

.TextCenter {
  text-align: center;
}

.UserSelectNone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/* Responsive */
@include media-smallscreen-down {
  .DialogPaperMobileWidth {
    width: calc(100% - 48px);
  }

  .DialogPaperMobileMargin {
    margin: 24px;
  }
}
