/* Mixins */
@import 'assets/scss/base';

/* Styles */
.NavBarInner {
  z-index: 1251;
}

.NavBarInnerBorderless {
  border-color: transparent;
}

.RedDot {
  background-color: #eb5757;
}

.LogoContainer {
  flex: 0;
}

.LogoPlayformAbout {
  left: convertPixelToRem(94);
}

.NavBarTabs {
  :global(.Mui-selected) {
    color: $colorWhiteHiTextOnDark;

    .NavBarTabText {
      font-weight: bold;
    }
  }
}

.NavBarTab {
  flex: 0 0 auto;
  min-width: auto;
  max-width: convertPixelToRem(200);
  color: $colorWhiteMedTextOnDark;
}

/* NavBar Drawer */
.NavDrawer {
  top: convertPixelToRem(49);
  z-index: 1099 !important;
}

.NavDrawerContent {
  padding-top: convertPixelToRem(48 + 8);
}

/* NavBar Mobile */
.NavMobileDrawer {
  top: convertPixelToRem(49);
  z-index: 1098 !important;
}

.NavMobileDrawerToggle {
  max-width: convertPixelToRem(200);
}

.NavMobileDrawerToggleIcon {
  margin-left: $value5;
}

.NavMobileDrawerPaper {
  width: convertPixelToRem(279);
}

.NavMobileDrawerContent {
  padding-top: convertPixelToRem(48 + 8);
}

/*  */
.BadgeInner {
  background-color: #eb5757;
}

/*  */
.NavBarDropdown {
  height: calc(100% - 2px);
  right: convertPixelToRem(57);
}

.NavBarDropdownMenu {
  min-width: convertPixelToRem(164);
}

/* Responsive */
@include media-smallscreen {
  .NavNotificationsDrawerPaper {
    max-width: convertPixelToRem(360);
  }
}

@include media-mediumscreen {
  .NavBarTab {
    min-width: convertPixelToRem(164);
  }
}

@include media-largescreen {
  .NavBarInner {
    z-index: 1100;
  }
}
