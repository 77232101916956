@import 'assets/scss/base';

/* Styles */
.BannerPlaceholder {
  height: 54px;
  display: flex;
  width: 100%;

  &.Float {
    position: absolute;
    top: 73px;
    z-index: 1500;
  }

  &.DialogBlend {
    height: auto;

    .Banner {
      position: relative;
      .BannerInner {
        padding: 12px 20px;
        background: #1e1e1e;
      }
    }
  }

  &.CTA {
    height: auto;
    // position: fixed;
    // z-index: 1201;
    // bottom: $statusBarHeight;

    .Banner {
      position: static;
      border-top: 1px solid rgba(255, 255, 255, 0.12);

      .BannerInner {
        min-height: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.Static {
    height: auto;

    .Banner {
      position: static;
    }
  }
}

/*  */
.Banner {
  width: 100%;
  z-index: 1252;
  position: fixed;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #121212;
}

.BannerInner {
  &.FullSize {
    & > div {
      width: 100%;
    }
  }
}

.ActionButton {
  text-align: right;
}

/* Banner CTA Explore Page  */
.CTAButton {
  padding-top: 4px;
  padding-bottom: 4px;
}

/* Responsive */
@include media-smallscreen {
  .BannerInner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 54px;
  }

  .ActionButton {
    margin-left: 10px;
    margin-right: 15px;
    & > * {
      margin-left: 24px;
    }

    & > *:first-child {
      margin-left: 0;
    }
  }
}

@include media-mediumscreen {
  .BannerPlaceholder {
    &.CTA {
      .Banner {
        border-bottom: none;
      }
    }
  }

  .BannerInner {
    padding-left: 54px;
    padding-right: 54px;
  }
}

@include media-mediumscreen-down {
  .BannerPlaceholder {
    &.CTA {
      .Banner {
        border-top: none;
      }
    }
  }

  .BannerInner {
    padding: 4px 24px;
  }
}

@include media-smallscreen-down {
  .ActionButton {
    margin-top: 12px;
  }
}
