/* Imports */
@import 'assets/scss/base';

/* Styles */
/* Button Icon Contained */
.ButtonIconContained {
  color: $colorMedTextOnPrimary;
}

/* Button Icon Outlined */
.ButtonIconOutlined {
  border: 1px solid;
}

/* Button Light */
.ButtonTextLight {
  color: $colorWhiteMedTextOnDark;

  &:hover {
    background: $colorWhiteOverlay;
  }
}

.ButtonOutlinedLight {
  border-color: rgba(255, 255, 255, 0.18);
  color: $colorWhiteMedTextOnDark;

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    border-color: rgba(255, 255, 255, 0.18);
  }
}

.ButtonContainedLight {
  background: $colorWhiteMedTextOnDark;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
}

/* Button Size */
.ButtonSizeXsmall {
  padding: $value4 $value8;
}

.ButtonOutlinedSizeXsmall {
  padding: $value3 $value7;
}

.ButtonIconSizeXsmall {
  padding: 0;
}

.ButtonIconOutlinedSizeLarge {
  padding: convertPixelToRem(23);
}

.ButtonIconOutlinedSizeMedium {
  padding: convertPixelToRem(11);
}

.ButtonIconOutlinedSizeSmall {
  padding: convertPixelToRem(5);
}

.ButtonIconOutlinedSizeXsmall {
  padding: 0;
}

/*  */
.ButtonSizeXsmallStartIcon,
.ButtonSizeXsmallEndIcon {
  > *:nth-of-type(1) {
    font-size: 1rem;
  }
}

/* Button Icon Primary */
.ButtonIconOutlinedPrimary {
  border: 1px solid $colorWhiteMedTextOnDark;
  color: $colorWhiteHiTextOnDark;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: $colorWhiteHiTextOnDark;
  }
}

.ButtonIconContainedPrimary {
  background: $colorWhiteHiTextOnDark;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }
}

/* Button Icon Secondary */
.ButtonIconOutlinedSecondary {
  border: 1px solid rgba(249, 189, 25, 0.5);

  &:hover {
    background-color: rgba(249, 189, 25, 0.08);
    border-color: $colorSecondary;
  }
}

.ButtonIconContainedSecondary {
  background: $colorSecondary;
  color: $colorHiTextOnPrimary;

  &:hover {
    background: #f9b723;
  }
}

/* Button Icon Info */
.ButtonIconContainedInfo {
  background: #29b6f6;

  &:hover {
    background: #0288d1;
  }
}

/* Button Icon Warning */
.ButtonIconContainedWarning {
  background: #ffa726;

  &:hover {
    background: #f57c00;
  }
}

/* Button Icon Success */
.ButtonIconContainedSuccess {
  background: #c5dd2e;

  &:hover {
    background: #b7ce22;
  }
}

/* Button Icon Error */
.ButtonIconContainedError {
  background: #f3798f;

  &:hover {
    background: #c9546a;
  }
}

/*  */

/* Button Base */
.ButtonBase {
  &:hover {
    background: $buttonSecondaryHover;
    cursor: pointer;
  }
  &:active,
  &:focus {
    background: $buttonSecondaryFocus;
  }
}

/*  */
.ButtonLink {
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 18px;
  background: $buttonSecondary;
  color: #000;
  text-align: center;

  &:hover {
    background: #f9b723;
  }

  &.ButtonLinkText {
    background: none;
    color: $colorWhite;

    &:hover {
      background: none;
    }
  }

  .ButtonLinkFullWidthLabel {
    display: block;
    width: 100%;
  }

  &.Disabled {
    color: $buttonDisabledColor;
    box-shadow: none;
    background-color: $buttonDisabledBackground;
  }
}

/* Button Payment */
.ButtonPaypal,
.ButtonApplePay {
  height: 40px;
  border-radius: 4px;
}

.ButtonFullWidth,
.ButtonPaypalFullWidth,
.ButtonApplePayFullWidth {
  width: 100%;
}

/* Button Paypal */
.ButtonPaypal {
  background: #f7c557;
  color: #000;
}

.ButtonPaypalImage {
  fill: currentColor;
  height: 21px;
  display: inline-block;
  user-select: none;
}

/* Button Apple Pay */
.ButtonApplePay {
  background: #fff;
  color: #000;
}

.ButtonApplePayIcon {
  fill: currentColor;
  width: 100%;
  height: 20px;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
}

/* Button Text  */
.ButtonText {
  min-width: initial;
  padding: 0;
  font-size: inherit;
  vertical-align: inherit;
}

.ButtonTextLink {
  line-height: 1;
  color: $colorWhiteHiTextOnDark;

  &:hover {
    text-decoration: none;
    background: $colorWhiteHover;
  }
}

.ButtonTextLight {
  color: $colorWhiteMedTextOnDark;
}

.ButtonTextSecondary {
  color: $colorSecondary;
}

.ButtonTextError {
  color: $buttonErrorColor;
}

/* Button Bookmark */
.ButtonBookmark {
  text-align: left;
}

.ButtonBookmarkIconWrapper {
  position: relative;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: #323232;
    border-radius: 100%;
    transform: translate(-4px, -4px);
  }
}

.ButtonBookmarkIcon {
  position: relative;
  z-index: 1;
  font-size: 16px;
}

/* Button Emoji */
.ButtonEmoji {
  padding: 4px;
  border: $borderOnSurface;
  font-size: inherit;
  border-radius: 0;
}

.ButtonEmojiActive {
  background: $colorSecondaryFocus;
  border-color: $colorSecondary;
  color: $colorSecondary;

  &:hover {
    background: $colorSecondaryOverlay;
  }
}

/* Button Icon Vertical */
.ButtonIconVertical-root {
  flex-direction: column;

  color: $colorWhiteHiTextOnDark;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: $fontFamily;
  font-weight: 500;
  line-height: 1.1428571428571428;
  touch-action: manipulation;
  border-radius: 0;
  letter-spacing: 0.078125rem;
  text-transform: none;
}

.ButtonIconVertical-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.ButtonIconVertical-labelCompact {
  margin-top: -8px;
}

.ButtonIconVertical-icon {
  display: inherit;
  height: 44px;
  width: 44px;
  padding: 10px;
  margin: 0 0 4px;
  border-radius: 50%;
  font-size: 24px;
}

.ButtonIconVertical-colorDefault {
  &:hover .ButtonIconVertical-icon {
    background-color: $colorWhiteHover;
  }

  &:active .ButtonIconVertical-icon {
    background-color: $colorWhiteFocus;
  }
}

.ButtonIconVertical-colorLight {
  color: $colorWhiteMedTextOnDark;

  &:hover .ButtonIconVertical-icon {
    background-color: $colorWhiteOverlay;
  }

  &:active .ButtonIconVertical-icon {
    background-color: $colorWhiteFocus;
  }
}

.ButtonIconVertical-colorSecondary {
  color: $colorSecondary;

  &:hover .ButtonIconVertical-icon {
    background-color: $buttonSecondarySelectedOverlay;
  }

  &:active .ButtonIconVertical-icon {
    background-color: $buttonSecondaryOverlay;
  }
}

.ButtonIconVertical-outlined {
  .ButtonIconVertical-icon {
    border: $borderWhiteHiTextOnDark;
  }
}

.ButtonIconVertical-outlinedLight {
  .ButtonIconVertical-icon {
    border-color: rgba(255, 255, 255, 0.18);
  }
}

.ButtonIconVertical-outlinedSecondary {
  .ButtonIconVertical-icon {
    border-color: rgba(249, 189, 25, 0.5);
  }

  &:hover .ButtonIconVertical-icon {
    border-color: $colorSecondary;
  }
}

.ButtonIconVertical-disabled {
  color: rgba(255, 255, 255, 0.18);
}

/* Button Image */
.ButtonImageBase {
  padding: 0;
  margin: 0;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.ButtonImage {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-right: 6px;
  border: $borderWhiteMedTextOnDark;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background: $buttonSecondaryHover;
  }
}

.ButtonImageActive {
  background: $buttonSecondarySelectedOverlay;

  &:hover {
    background: $buttonSecondarySelectedOverlay;
  }
}

.ButtonImageLabel {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  height: 70px;
}

.ButtonImageTitle {
  display: block;
  padding: 4px 8px;
}

.ButtonImageImgWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 114px;
}

.ButtonImageImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/*  */
.ButtonImageActions {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: $borderOnSurface;
}
