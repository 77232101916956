/* Imports */
@import 'assets/scss/base';

/* Styles */
.UpscalePanelContainer {
  display: flex;
  flex-direction: column;
  background-color: $background;
  overflow: auto;
  height: 100%;

  &.VideoPanel {
    padding-top: 36px;
    z-index: 1101;
  }

  &.HomePage {
    position: absolute;
  }
}

.UpscalePanelContainerAbsolute {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.UpscalePanelContainerFixed {
  position: fixed;
  z-index: 1300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.UpscalePanelContainerProjectResult {
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    min-height: 96px;
  }
}

.UpscalePanelImageWrapper {
  min-height: 272px;
  padding-left: 24px;
  padding-right: 24px;

  @media only screen and (min-width: 375px) and (max-width: 599px) {
    min-height: 327px;
  }
}

.UpscalePanelSidebarColTitle {
  padding-bottom: 4px;
  margin-bottom: 16px;
  border-bottom: $borderOnSurface;
  color: $colorWhiteMedTextOnDark;
}

/*  */
.ButtonUpscaleItem {
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

/* Upscale Methods */
.UpscaleMethodsWrapper {
  padding-left: 24px;
  padding-right: 24px;
}

/* Upscale Controls */
.UpscaleControlsWrapper {
  padding-left: 24px;
  padding-right: 24px;
}

.UpscaleControlsInputRoot {
  width: 48px;
  padding: 0;
  color: $buttonDisabledColor;

  &:not(:hover):before {
    opacity: 0;
  }
}

.UpscaleControlsInput {
  height: 24px;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

/* Responsive */
@include media-smallscreen {
  .UpscalePanelImageWrapper {
    padding-top: 28px;
    padding-right: 12px;
    margin-top: 18px;
  }

  .UpscaleMethodsWrapper {
    height: 264px;
    padding-top: 45px;
    padding-left: 0;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .UpscaleMethodsTitle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: calc(100% - 24px);
    background-color: $background;
  }

  .UpscaleControlsWrapper {
    padding-left: 0;
  }

  @media only screen and (min-width: 767px) and (max-width: 1279px) {
    .UpscaleMethodsWrapper {
      height: 348px;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1279px) and (min-height: 650px) {
    .UpscaleMethodsWrapper {
      height: auto;
    }
  }
}

@include media-mediumscreen {
  .UpscalePanelImageWrapper {
    padding: 20px 24px 24px 72px;
  }

  .UpscalePanelSidebar {
    padding-left: 0;
    padding-right: 0;
  }

  .UpscaleMethodsWrapper {
    width: 614px;
    height: initial;
    padding-top: 37px;
    padding-right: 24px + 362px;
    padding-bottom: 60px;
    overflow-y: auto;
  }

  .UpscaleMethodsTitle {
    width: calc(100% - (24px + 362px));
  }

  .UpscaleControlsWrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 362px;
    max-height: 100%;
    padding-top: 0;
    padding-right: 72px;
    overflow: auto;
  }

  .UpscaleControlsSelect {
    max-width: 160px;
  }

  .UpscaleListHead {
    height: 88px;
    padding-left: convertPixelToRem(72);
    padding-right: convertPixelToRem(72);
  }
}

@include media-largescreen {
  .UpscalePanelImageWrapper {
    padding-left: calc((100vw - 1296px) / 2);
    padding-right: 134px;
  }

  .UpscaleMethodsWrapper {
    width: calc(((100vw - 1296px) / 2) + 636px);
    padding-right: calc((100vw - 1296px) / 2 + 24px + 306px);
  }

  .UpscaleMethodsTitle {
    width: calc(100% - ((100vw - 1296px) / 2 + 24px + 306px));
  }

  .UpscaleControlsWrapper {
    width: calc(((100vw - 1296px) / 2) + 306px);
    padding-right: calc((100vw - 1296px) / 2);
  }

  .ButtonUpscaleItem {
    padding: 24px;
  }

  .UpscaleControlsSelect {
    max-width: none;
  }
}

@include media-xlargescreen {
  .UpscalePanelImageWrapper {
    padding-left: 320px;
  }

  .UpscaleMethodsWrapper {
    width: 948px;
    padding-right: 642px;
  }

  .UpscaleMethodsTitle {
    width: 306px;
  }

  .UpscaleControlsWrapper {
    width: 618px;
    padding-right: 312px;
  }
}

@include media-smallscreen-down {
  .UpscalePanelSidebar {
    padding-top: 56px;
  }
}
