/* Imports */
@import 'assets/scss/base';

/* Styles */
/* Input */
.Input {
  position: relative;
}

.InputDefault {
  padding-left: 0;
  padding-right: 0;
}

.InputFilled {
  background-color: rgba(255, 255, 255, 0.07);

  &:hover:not(:global(.Mui-disabled)):before {
    // border-width: 1px;
    border-bottom-width: 1px;
  }
}

.InputFilledInput {
  padding-left: $value12;
  padding-right: $value12;
}

.InputTiny,
.InputTinyWithPaddingY {
  :global(.MuiInput-input) {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
  }

  &:not(:disabled):after {
    border-width: 1px;
  }
}

.InputTiny {
  padding: 0;
}

.InputTinyWithPaddingY {
  padding-top: 0;
  padding-bottom: 0;
}

.InputAutoWidth {
  max-width: 100%;
}

.InputAutoWidthHelper {
  position: absolute !important;
  bottom: 150vh !important;
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  font-size: 1rem;
  pointer-events: none;
}

.InputAutoWidthHelperInner {
  position: static !important;
  display: inline-block !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1em;
  line-height: 1;
  letter-spacing: 0;
}

/* Input Credit Card */
.InputCreditCardWrapper {
  position: relative;
  display: flex;
  height: 56px;
}

.InputCreditCardBg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.InputCreditCard {
  height: 100%;
  padding: 0 12px;
  background: none;
  border: 0;
  outline: 0;
}

.InputCreditCardNumber {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.InputExpired {
  width: 90px;
}

.InputSecurityCode {
  width: 90px;
}

/* Input Search */
.InputSearch {
  text-align: left;
}

.InputSearchStaticSmall {
  :global(.MuiInputAdornment-positionStart) {
    margin-right: 2px;
  }

  :global(.MuiInput-input) {
    font-size: $value14;
  }
}

/* Input Search Collapsible */
$input-search-transition-duration: 0.2s;

.InputSearchCollapsibleRoot {
  width: 48px;
  height: 56px;
}

.InputSearchCollapsibleActionInner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 56px;
}

.InputSearchCollapsibleContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: #121212;
  transition: width $input-search-transition-duration linear;
  overflow: hidden;
}

.InputSearchCollapsible {
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: padding 0.1s linear, background 0.1s linear;

  &:before {
    transition: opacity $input-search-transition-duration linear;
  }
}

.InputSearchCollapsed {
  .InputSearchCollapsibleContainer {
    width: 0;
  }

  .InputSearchCollapsible {
    &:before {
      opacity: 0;
    }
  }

  .InputSearchCollapsibleToggleShow {
    visibility: visible;
    transition: visibility $input-search-transition-duration linear
      $input-search-transition-duration;
  }
}

.LoadingCollapsibleContainer {
  position: absolute;
  top: 15px;
  right: 15px;
}

.InputSearchCollapsibleToggleHide {
  margin: 0;
  transition: opacity $input-search-transition-duration linear;
}

/*  */
.Textarea {
  font: inherit;
  // font-size: 16px;
  padding: 16px;
  line-height: 24px;
  width: 100%;
  border: none;
  color: $colorWhiteHiTextOnDark;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 0;

  &:focus {
    outline: none;
    outline-offset: 0;
  }
}
