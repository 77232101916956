.SortBy {
  display: flex;
  align-items: center;
  color: #c4c4c4;

  &__Ordering {
    width: 24px;
    height: 24px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.SortByDropdownMenu {
  height: 100%;
}

.SortByButtonComponent {
  height: 100%;
  min-width: 0;
  padding: 0 6px;
  margin-left: 6px;
  margin-right: 6px;
}
