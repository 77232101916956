/* Mixins */
@import 'assets/scss/base';

/* Styles */
.NavBar {
  position: relative;
  background-color: $background;
  border: 0;
  box-shadow: none;
}

.NavBarInner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  &.NavBarMobileDrawerOpen {
    border-bottom: 0;
  }

  &.NavBarInnerBorderless {
    border-bottom: 0;
  }
  &.NavBarInnerTransparent {
    background: none;
  }
}

.NavBarButtonContainer {
  display: flex;
  margin-right: $value72;
  justify-content: space-between;
  width: 100%;
}

.LogoContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: $value48;
  margin-right: convertPixelToRem(38);
  flex: 0;
  color: $colorWhiteHiTextOnDark;
}

.ButtonProjectBack {
  background: $background;

  &:hover {
    background: $background;
  }
}

.ButtonProjectBackIcon,
.ButtonProjectBackText {
  display: block;
}

.ButtonProjectBackIcon {
  font-size: $value28;
}

.NavBarProjectContainer {
  display: flex;
  height: $value48;
  align-items: center;
  justify-content: space-between;

  .Title {
    margin: 0 $value12;
    padding-bottom: $value7;
    min-width: convertPixelToRem(250);
    max-width: convertPixelToRem(500);

    & > div {
      padding: 0;
      background: none;
    }
  }

  .TitleStaticWrapper {
    display: flex;
    align-items: flex-end;
    width: 1%;
    min-width: 0;
    flex: 1 1 auto;
  }

  .TitleStatic {
    display: none;
    padding: $value10;
    // min-width: 300px;
  }

  .TitleStaticBlock {
    display: block;
  }

  .AutosavedInfo {
    display: block;
    // min-width: convertPixelToRem(200);
    padding: $value10 0;
    color: $textGray;
  }
}

.NavBarProjectLeftComponent {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  width: 1%;
  min-width: 0;
  // max-width: calc(100% - 357px);
}

.NavBarProjectRightComponent {
  display: flex;
  align-items: center;
  height: 100%;
}

.NavBarProjectLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $colorWhiteHiTextOnDark;
  color: $colorWhiteHiTextOnDark;
  min-width: convertPixelToRem(38);
  min-height: convertPixelToRem(38);
  margin-left: $value5;

  & > svg {
    width: convertPixelToRem(38);
    height: convertPixelToRem(38);
  }

  .ButtonProjectBack {
    display: none;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;

    .ButtonProjectBackIcon {
      &:first-child {
        margin-right: 0;
      }
    }
  }
  &.WithBackRoute:hover,
  &.WithBackRoute.PersistentBackButton {
    .ButtonProjectBack {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & > svg {
      display: none;
    }
  }
}

/* Mobile Menu  */
.NavbarGeneralLogoMobile {
  width: convertPixelToRem(38);
  height: convertPixelToRem(38);
  margin-left: 5px;
}

.NavbarGeneralLogo {
  height: 36px;
}

/*  */
.ButtonProjectToggleRename {
  display: none;
}

/* */
.NavBarUpscaleRightComponent {
  padding-right: $value24;
}

/* Responsive */
@include media-smallscreen {
  .NavBarProjectContainer {
    height: 72px;
  }

  .NavBarProjectLogo {
    min-width: 56px;
    min-height: 56px;

    & > svg {
      width: 56px;
      height: 56px;
    }
  }

  .ButtonProjectBackIcon {
    font-size: 36px;
  }

  .ButtonProjectToggleRename {
    display: block;
  }
}

@include media-mediumscreen {
  .LogoContainer {
    height: initial;
    margin: 0 convertPixelToRem(38) 0 $value24;
    padding-top: $value18;
    padding-bottom: $value18;
    flex: 0;
  }

  .NavbarGeneralLogoMobile {
    display: none;
  }

  .ButtonProjectToggleRename {
    display: none;
  }

  /*  */
  .NavBarSketchToImageContainer {
    padding-right: $value24;
  }

  /*  */
  .NavBarProArtFilterContainer {
    padding-right: $value24;
  }

  /* NavBar Mix Image */
  .NavBarMixImage {
    margin-right: convertPixelToRem(320);
    transition: margin 0.25s linear;

    &.LoadingView {
      margin-right: 0;
      padding-right: $value16;
    }

    &.FullSize {
      margin-right: 0;
    }
  }

  /*  */
  .NavBarUpscaleRightComponent {
    padding-right: $value72;
  }
}

// @include media-largescreen {}

@include media-largescreen-down {
  .NavBarProjectContainer {
    .Title {
      min-width: convertPixelToRem(264);
    }
  }

  .NavBarInner {
    z-index: 1300;
  }

  @media only screen and (max-width: 1199px) {
    .NavBarMixImage {
      .Title {
        min-width: convertPixelToRem(160);
      }
    }
  }
}

@include media-mediumscreen-down {
  .NavbarGeneralLogo {
    display: none;
  }

  .NavBarInner {
    z-index: 1300;
  }

  .NavBarButtonContainer {
    margin-right: $value24;
  }

  .TitleStatic {
    display: block;
    font-size: $value20;
    line-height: $value24;
    letter-spacing: 0.15px;
  }

  .Title {
    display: none;
  }
}

@include media-smallscreen-down {
  .ButtonProject {
    min-width: convertPixelToRem(82);
    justify-content: center;

    &.ButtonProjectContinueMobile {
      position: relative;
    }
  }

  .ButtonProjectLabel {
    display: none;
  }

  .NavBarSketchToImageContainer {
    .TitleStaticWrapper {
      align-items: center;
    }
    .HideOnSmallScreen {
      display: none;
    }
  }
}

/* Utilities */
