/* Imports */
@import 'assets/scss/base';

/* Styles */
.UpscaleUploadButton {
  min-height: convertPixelToRem(150);
  color: $colorSecondary;
  border: 1px solid rgba(249, 189, 25, 0.5);

  &:hover {
    border: $borderSecondary;
    background-color: rgba(249, 189, 25, 0.08);
  }
}

.UpscaleUploadButtonIcon {
  font-size: $value32;
}
