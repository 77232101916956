/* Mixins */
@import 'assets/scss/base';

/* Styles */
.TextField {
  position: relative;
}

.TextFieldFilledPrimaryAlt,
.TextFieldFilledSecondaryAlt {
  :global(.MuiFilledInput-underline):before {
    content: none;
  }
}
