/* Mixins */
@import 'assets/scss/base';

/* Styles */

.DialogSaveCardActions {
  width: 100%;
  padding: 0;
}

.DialogSaveCardContent {
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
}

.DialogSaveCardLoadingWrapper {
  padding-top: 96px;
  padding-bottom: 96px;
  text-align: center;
}

@include media-smallscreen {
  .DialogSaveCardContent,
  .DialogSaveCardLoadingWrapper {
    width: 560px;
  }

  .DialogSaveCardContent {
    padding: 48px;
  }
}
