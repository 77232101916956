@import 'assets/scss/base';

/* Style */
.LoadingContainer {
  padding-top: convertPixelToRem(64);
  padding-bottom: convertPixelToRem(64);
  text-align: center;
}

.LoadingContainer {
  position: absolute;
  z-index: 1;
  bottom: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  pointer-events: none;

  &.LoadingTop {
    top: 56px;
    padding: 84px 20px;
  }
}

.LoadingContainerFixed {
  position: fixed;
  left: 0;
}

/* Utils */
.OverflowHidden {
  overflow: hidden;
}

.WidthFull {
  width: 100%;
}

.HeightFull {
  height: 100%;
}
