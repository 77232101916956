@import 'assets/scss/base';

.AppPage {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: $background;
  overflow: hidden;
}

.AppPageHidden {
  content-visibility: hidden;
  visibility: hidden;

  position: absolute;
  // height: 1px;
  // width: 1px;
  border: 0px;
  // margin: -1px;
  // padding: 0px;
  content-visibility: hidden;
  visibility: hidden;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0px, 0px, 0px, 0px);
  pointer-events: none;
  user-select: none;
}

.ErrorPageBox {
  max-width: convertPixelToRem(516);
  min-height: convertPixelToRem(415);
  padding: convertPixelToRem(44);
}

/* App SubPage */
.AppSubpageModal {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0 !important;
}

.AppSubpageBackdrop {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  outline: 0 !important;
}

.AppSubpage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $background;
  outline: 0 !important;
}
