/* Mixins */
@import 'assets/scss/base';

/* Styles */
.SliderContainer {
  line-height: 1;
  font-size: 0;
}

.SliderDisabledThumb {
  pointer-events: none;
}

/*  */
.SliderColorSecondary {
  :global(.MuiSlider-markActive) {
    background-color: $colorWhiteHiTextOnDark;
  }
}

.SliderColorSecondaryAlt {
  :global(.MuiSlider-mark) {
    background-color: $colorWhiteHiTextOnDark;
  }

  :global(.MuiSlider-track) {
    opacity: 1;
  }

  :global(.MuiSlider-rail) {
    background-color: rgba(249, 189, 25, 0.5);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $colorWhiteFocus;
    }
  }
}
