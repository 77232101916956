@import 'assets/scss/base';

/* Styles */
.BannerSubscriptionUpsellPlaceholder {
  padding: 32px 26px;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  height: auto;
  // border-top: none;
  background: $background;

  .BannerSubscriptionUpsell {
    position: static;
    border: none;
    background: none;
  }

  &.FloatBottom {
    bottom: 0;
    width: 100vw;
    z-index: 1400;
    position: fixed;
    background: $dialogSurfaces;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  }
}

/* Responsive */
@include media-mediumscreen-down {
  .BannerSubscriptionUpsellPlaceholder {
    padding: 15px 0;
  }
}
